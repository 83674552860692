<template>
  <div>
    <div class="row justify-content-center justify-content-around">
      <!-- <div class="demo-inline-spacing">
            <b-button variant="outline-primary" @click="showAlert">
              Basic
            </b-button>
          </div> -->
      <div class="card col-12 col-md-12 p-1">
        <div>
          <div class="title head">
            <p class="h2" style="color: #558cef">ฟอนต์</p>
            <hr />
          </div>
          <!-- search input -->
          <div class="text-center">
            <v-select class="w-50" v-model="selectedFont" :options="fontOptions" @input="changeFont" />
            <br />
            <b-button @click="save(selectedFont)"> บันทีก</b-button>
            <!-- <p style="font-family: 'Prompt', sans-serif">This is a custom font สวัสดีครับ 1234567890</p> -->
            <!-- <div class="col-12 col-md-3 mb-1">
              <b-button v-b-modal.modal-1 class="btn" variant="success" @click="add_user"> Import </b-button>
            </div> -->
            <!-- <b-form-group class="col-12 col-md-6">
              <div class="d-flex align-items-center">
                <label class="mr-1">ค้นหา</label>
                <b-form-input v-model="searchTerm" placeholder="ค้นหา" type="text" class="d-inline-block" />
                <div>
                  <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                    <template v-slot:button-content>
                      <b-button variant="outline-primary"
                        ><feather-icon icon="ShareIcon" class="mr-50" />Export/Import
                        <feather-icon icon="ChevronDownIcon" class="mr-50"
                      /></b-button>
                    </template>
                    <b-dropdown-item v-b-modal.modal-Import>
                      <feather-icon icon="FileIcon" class="mr-50" />
                      Import Excel
                    </b-dropdown-item>
                    <b-dropdown-item @click="exportExcel">
                      <feather-icon icon="FileIcon" class="mr-50" />
                      Export Excel
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </b-form-group> -->
          </div>
          <p class="text-center" :style="`font-family:${selectedFont.label},sans-serif`">
            10 พฤษภาคม 2566 This is a custom font สวัสดีครับ 1234567890
          </p>
        </div>
      </div>
      <!-- <div class="card col-12 col-md-2"></div> -->
    </div>

    <!-- //model -->
    <div></div>
  </div>
</template>

<script>
import { BFormInput, BRow, BFormGroup, BButton, BFormFile, BFormSelect } from "bootstrap-vue";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import axios from "axios";
import API from "@/views/connectDB/condb.js";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import readXlsxFile from "read-excel-file";
import _ from "lodash";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    BFormSelect,
    BFormFile,
    BRow,
    BButton,
    vSelect,
    BFormInput,
    BFormGroup,
  },

  data() {
    return {
      selectedFont: "เลือก ฟอนต์",
      fontOptions: [
        { label: "Arial", value: "Arial" },
        { label: "Helvetica", value: "Verdana" },
        { label: "Gill Sans", value: "Times New Roman" },
        { label: "Lucida", value: "Noto Serif Thai" },
        { label: "Kanit", value: "Noto Serif Thai" },
        { label: "Sarabun", value: "Noto Serif Thai" },
        
        // เพิ่มตัวเลือกฟอนต์ที่ต้องการเพิ่มเติมที่นี่
      ],
      API: `${API}`,
      show: false,
      fromIn: {
        username: "",
        password: "",
        firstname: "",
        lastname: "",
      },
      Previewimage: null,
      image: null,
      number: "",
      dir: false,
      status: { title: "ผู้ใช้ทั่วไป" },
      statuss: [
        { title: "แอดมิน" },
        { title: "ผู้ใช้ทั่วไป" },
        { title: "เจ้าหน้าที่พัสดุ" },
        { title: "ผู้ตรวจสอบ" },
        { title: "ลาออก" },
      ],
      workG: "",
      workGs: [],
      department: "",
      departments: [],
      columns: [
        {
          label: "วันที่สร้าง",
          field: "createdAt",
        },
        {
          label: "e_number",
          field: "e_number",
        },
        {
          label: "unique_number",
          field: "unique_number",
        },

        {
          label: "วันที่แก้ไข",
          field: "updatedAt",
        },

        {
          field: "ดูรายการ",
        },
      ],
      rows: [],
      total: "",
      pageLength: 100,
      currentPage: 1,
      searchTerm: "",
      group_head_value: false,
      month: [
        "",
        "มกราคม",
        "กุมภาพันธ์ ",
        "มีนาคม ",
        "เมษายน ",
        "พฤษภาคม ",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
      ],
      formData: {
        unique_number: "",
        e_number: "",
      },
      intext: "",
    };
  },
  computed: {
    layout() {
      // console.log(this.$route.mete.permissions);
    },
    example: {
      cache: false,
      get: function () {
        return Date.now() + this.msg;
      },
    },
  },
  created() {
    //this.get_data_table();
    const fonts = localStorage.getItem("font");
    console.log(fonts);
    this.selectedFont = fonts ? fonts : 'Sarabun';
  },
  methods: {
    save(value) {
      console.log(value);
      localStorage.setItem("font", value.label);
      location.reload();
    },
    changeFont() {
      // ทำสิ่งที่คุณต้องการเมื่อฟอนต์ถูกเปลี่ยน
    },
    async Import_Excel() {
      this.show = true;
      const { access_token } = await this.access_token();
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const url = `${API}mapping`;
      var data = [];
      for (let i in this.Excel) {
        data.push({
          unique_number: this.Excel[i][0],
          e_number: this.Excel[i][1],
        });
      }
      console.log(data);
      data.forEach(async (element) => {
        const post_data = {
          unique_number: element.unique_number,
          e_number: element.e_number.toString(),
        };
        // console.log(post_data);
        const res = await axios.post(url, post_data, head);
        console.log(res);

        // console.log(res);
      });
      this.show = false;
      this.get_data_table();
    },
    add_user() {
      this.intext = "";
      this.formData.unique_number = "";
      this.formData.e_number = "";
      this.group_head_value = false;
    },
    edit_add(value) {
      // console.log(value);
      this.intext = "แก้ไข";
      this.formData.unique_number = value.unique_number;
      this.formData.e_number = value.e_number;
    },
    group_head(value) {
      console.log(value);
    },
    async delete_add(value) {
      console.log(value);
      const { e_number, unique_number } = value;
      Swal.fire({
        title: "คุณต้องการจะลบข้อมูลใช่หรือไม่",
        text: `${e_number} ${unique_number} `,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          const { access_token } = await this.access_token();
          const url = `${API}mapping/${value.id}`;
          const res = await axios.delete(url, {
            headers: {
              Authorization: access_token,
            },
          });

          console.log(res.data);
          if (res.data.status === 200) {
            Swal.fire({
              icon: "success",
              title: "ลบข้อมูลสำเร็จ!",
              text: `รายการนี้ ถูกลบเเล้ว `,
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "ลบข้อมูลไม่สำเร็จ!",
              text: `${res.data.message}`,
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
          }
        }
        this.get_data_table();
      });
    },
    exportExcel() {
      saveExcel({
        data: this.rows,
        fileName: "Import",
        columns: [
          {
            title: "Import",
            headerCellOptions: { textAlign: "center" },
            children: [
              {
                title: "วันที่สร้าง",
                field: "createdAt",
              },
              {
                title: "e_number",
                field: "e_number",
              },
              {
                title: "unique_number",
                field: "unique_number",
              },
              {
                title: "วันที่แก้ไข",
                field: "updatedAt",
              },
            ],
          },
        ],
      });
    },
    async up_user(value) {
      console.log(value.row);
      console.log(this.group_head_value);
      const { access_token } = await this.access_token();

      const fullname = `${value.row.firstname} ${value.row.lastname}`;

      // console.log(this.status.title);

      const url_a = `${API}setUserPermission`;
      const data_a = {
        username: value.row.username,
        usertype: value.row.usertype.title || value.row.usertype,
      };
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res_a = await axios.put(url_a, data_a, head);

      const url_b = `${API}user/${value.row.id}`;
      const data_b = {
        username: value.row.username,
        work_gid: value.row.work_gid.work_gname,
        dep_id: value.row.dep_id.dep_name,
        firstname: value.row.firstname,
        lastname: value.row.lastname,
      };
      const res_b = await axios.put(url_b, data_b, head);
      console.log(res_a);
      console.log(res_b);
      const api_group_head = `${API}headWorkgroup`;
      const data = {
        head: `${this.group_head_value}`,
        fullname: fullname,
      };
      const group_header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res_aaa = await axios.put(api_group_head, data, group_header);
      console.log(res_aaa);
      if (res_aaa.data.status === 200) {
        Swal.fire({
          position: "top-center",
          icon: "success",
          title: "บันทึก",
          showConfirmButton: false,
          timer: 1500,
        });
        this.get_data_table();
      } else {
        Swal.fire({
          position: "top-center",
          icon: "error",
          title: res_aaa.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
      this.get_data_table();
    },
    async get_data_table() {
      this.show = true;
      const { access_token } = await this.access_token();
      const url = `${API}mapping?_page=${this.currentPage}&_limit=${this.pageLength}&_sort=-1`;
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, head);
      console.log(res);
      const res_map = res.data.message.data.map((res_x) => {
        return {
          ...res_x,
          image: res_x.image ? JSON.parse(res_x.image) : [],
          createdAt: `${parseInt(res_x.createdAt.split("T")[0].split("-")[2])} ${
            this.month[parseInt(res_x.createdAt.split("T")[0].split("-")[1])]
          } ${Number(res_x.createdAt.split("T")[0].split("-")[0]) + 543}`,
          updatedAt: `${parseInt(res_x.updatedAt.split("T")[0].split("-")[2])} ${
            this.month[parseInt(res_x.updatedAt.split("T")[0].split("-")[1])]
          } ${Number(res_x.updatedAt.split("T")[0].split("-")[0]) + 543}`,
        };
      });

      // console.log(res_map);
      //  this.rows = res_map;
      this.rows = res_map;
      this.total = res.data.message.total;
      this.show = false;
    },
    handlePagechange(event) {
      this.currentPage = event;
      this.get_data_table();
    },
    handleChangePage(event) {
      this.pageLength = event;
      this.get_data_table();
    },

    async work_select(event) {
      const { access_token } = await this.access_token();
      const m_gid = event.work_gid;
      // console.log(m_gid);
      const url = `${API}departments?work_gid=${m_gid}`;
      const header = {
        headers: {
          Authorization: await access_token,
        },
      };
      const res = await axios.get(url, header);

      this.departments = res.data.message.data;
    },
    FileChange() {},
    onFileChange(e) {
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      readXlsxFile(xlsxfile).then((rows) => {
        const xx = rows.filter((element, index) => {
          return index >= 2;
        });
        //console.log(xx);
        const num = {};
        const doubly = {};
        const uu = xx.filter((res, index) => {
          let empty = true;
          res.map((res2, index) => {
            if (res2 === null || res2 === "null" || res2 === undefined || res2 === "undefined" || res2 === "") {
              empty = false;
            }

            if (index === 1) {
              if (num[res2]) {
                num[res2]++;
                doubly[res2] = res;
              } else {
                num[res2] = 1;
              }
            }
          });
          return empty && res;
        });
        if (!!!_.isEmpty(doubly)) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: `ประเภททรัพย์สินซ้ำ ${Object.values(doubly)}`,
            showConfirmButton: true,
          });
          this.Excel = uu;
        } else {
          this.Excel = uu;
        }
        console.log(this.Excel);
      });
    },
    async access_token() {
      return (
        await axios.post(`${API}permit`, "", {
          headers: {
            Authorization: localStorage.getItem("storedData") && JSON.parse(localStorage.getItem("storedData")),
          },
        })
      ).data.message;
    },
    // basic
    async showAlert() {
      const { access_token } = await this.access_token();
      const head = {
        headers: {
          Authorization: await access_token,
        },
      };
      if (this.intext === "แก้ไข") {
        const url = `${API}mapping/${this.formData.unique_number}`;
        const data = {
          e_number: this.formData.e_number,
        };
        const res = await axios.put(url, data, head);
        if (res.data.status === 200) {
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: "บันทึก",
            showConfirmButton: false,
            timer: 1500,
          });
          // this.formData
          this.get_data_table();
        } else {
          Swal.fire({
            position: "top-center",
            icon: "error",
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        const url = `${API}mapping`;
        const data = {
          unique_number: this.formData.unique_number,
          e_number: this.formData.e_number,
        };
        const res = await axios.post(url, data, head);
        console.log(res);
        if (res.data.status === 200) {
          Swal.fire({
            position: "top-center",
            icon: "success",
            title: "บันทึก",
            showConfirmButton: false,
            timer: 1500,
          });
          // this.formData
          this.get_data_table();
        } else {
          Swal.fire({
            position: "top-center",
            icon: "error",
            title: res.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
  },
};
</script>
<style lang="scss">
$selectedFont: selectedFont;
.v-select__dropdown-toggle,
.v-select__single-value {
  font-family: selectedFont, sans-serif;
}
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
